body {
  margin: 0 !important;
  direction: rtl;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  box-sizing: border-box;  

}

* {
  font-family: "Varela Round", sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

.css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root{
  padding: 13px !important;
}

.css-1qiyjve-MuiSvgIcon-root{
  width: 1.2em !important;
  height: 1.2em !important;
}

.css-1ryh043-MuiSvgIcon-root{
  width: 1.2em !important;
  height: 1.2em !important;
}


#NagishLiTrigger{  
  max-width: 50px !important;
  max-height: 50px !important;
  top: 16% !important;
  left: 0.5% !important;
  right: unset !important;
  
}
.nagishli-submenu-state-icon, .nagishli-option-icon, .nagishli-trigger-icon, .nagishli-tabs .nagishli-tabs-slider .nagishli-tabs-indicator, .nagishli-overlay-content-close:focus{
  background-color: #D7EAF9 !important;

}
#NagishLiTrigger img{  
  max-width: 40px !important;
  max-height: 40px !important;
  margin-left: -5px;
  margin-top: -5px;
  content:url("https://cdn.icon-icons.com/icons2/384/PNG/512/accessibility-directory-icon_37718.png");
  
}

.blockp-3bg-slate-300{
  margin-right: 5% !important; 
  
}

.MuiButtonBase-root{
  color: #898989 !important;
}

.MuiMenuItem-gutters{
  color: black !important;
  margin: 1% !important;
}

@media screen and (max-width: 952px) {
  .blockp-3bg-slate-300 {
    margin-right: 33% !important; 
  }
  .MuiButtonBase-root{
    color: black !important;
  }
}


@media screen and (max-width: 652px) {
  .whatsUppMobile{
    display: block !important;
  }

}
